/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import { DateTime } from 'luxon';
import { GenerateXlsx } from '../Conciliation/files/generate-xlsx';

export default class GetFgtsFile {
    _fileGenerator = new GenerateXlsx()

    // _insertPart(part, title) {
    //     this._fileGenerator.insertLineStyled({
    //         firstColumn: title.toUpperCase(),
    //     }, { bold: true, fontSize: 11 });

    //     this._fileGenerator.insertBlankLine();

    //     this._insertTableTitles({
    //         'Razão Social',
    //         secondColumn: part.tax_id.length === 11 ? 'CPF' : 'CNPJ',
    //         thirdColumn: 'Endereço',
    //         fourthColumn: 'CEP',
    //         fifthColumn: 'Cidade',
    //         sixthColumn: 'UF',
    //     });

    //     this._fileGenerator.insertLine({
    //         firstColumn: part.legal_tax_name,
    //         secondColumn: part.tax_id,
    //         thirdColumn: part.address,
    //         fourthColumn: part.zip,
    //         fifthColumn: part.city,
    //         sixthColumn: part.state,
    //     });
    // }

    // eslint-disable-next-line class-methods-use-this
    _insertPart(data) {
        return [
            data.legal_tax_name,
            data.tax_id,
            data.address,
            data.zip,
            data.city,
            data.state,
        ];
    }

    _insertTableTitles(keys) {
        this._fileGenerator.insertLineStyled(keys, { bold: true, fontSize: 10 });
    }

    _insertOperationCaracteristic(data) {
        // this._insertTableTitles({
        //     firstColumn: 'Valor Líquido do Empréstimo (R$)',
        //     secondColumn: 'Valor do IOF ( R$)',
        //     thirdColumn: 'Tarifa de Cadastro (R$)',
        //     fourthColumn: 'Taxa Efetiva de Juros (a.m.)',
        //     fifthColumn: 'Taxa Efetiva de Juros (a.a.)',
        //     sixthColumn: 'Despesas (R$)',
        //     seventhColumn: 'Custo Efetivo Total (a.m.) (%)',
        //     eighthColumn: 'Custo Efetivo Total (a.a.)',
        //     ninthColumn: 'Custo Efetivo Total (R$)',
        //     tenthColumn: 'Valor Total do Empréstimo (SEM juros)',
        //     eleventhColumn: 'Valor Total do Empréstimo (COM juros)',
        //     twelthColumn: 'Data de Vencimento da CCB',
        //     thirteenthColumn: 'Praça de Pagamento desta CCB',
        //     fourteenthColumn: 'PARCELAS DEVIDAS',
        // });

        // this._fileGenerator.insertLineStyled({
        //     firstColumn: data.net_loan_amount,
        //     secondColumn: data.iof_amount,
        //     thirdColumn: data.registration_fee,
        //     fourthColumn: data.effective_monthly_interest_rate,
        //     fifthColumn: data.effective_annual_interest_rate,
        //     sixthColumn: data.expenses,
        //     seventhColumn: data.effective_cost_total_monthly,
        //     eighthColumn: data.effective_cost_total_annual,
        //     ninthColumn: data.effective_cost_total,
        //     tenthColumn: data.total_loan_amount_without_interest,
        //     eleventhColumn: data.total_loan_amount_with_interest,
        //     twelthColumn: data.due_date_ccb,
        //     thirteenthColumn: data.payment_location_ccb,
        //     fourteenthColumn: data.installments_due,
        // });

        return [
            data.net_loan_amount,
            data.iof_amount,
            data.registration_fee,
            data.effective_monthly_interest_rate,
            data.effective_annual_interest_rate,
            data.expenses,
            data.effective_cost_total_monthly,
            data.effective_cost_total_annual,
            data.effective_cost_total,
            data.total_loan_amount_without_interest,
            data.total_loan_amount_with_interest,
            data.due_date_ccb,
            data.payment_location_ccb,
            data.installments_due,
        ];
    }

    _insertTitle(title) {
        this._fileGenerator.insertLineStyled({
            firstColumn: title,
        }, { bold: true, fontSize: 12 });
    }

    generate(
        data,
    ) {
        // data.forEach(({ file_name: fileName, operation }) => {
        //     this._fileGenerator.insertLineStyled({
        //         firstColumn: fileName,
        //     }, { bold: true, fontSize: 14, fontColor: '2E2C6C' });

        //     this._fileGenerator.insertBlankLine();
        //     this._insertTitle('PARTES');

        //     this._fileGenerator.insertBlankLine();
        //     this._insertPart(operation.parts.creditor, 'Credor');
        //     this._fileGenerator.insertBlankLine();
        //     this._insertPart(operation.parts.issuer, 'Emitente');
        //     this._fileGenerator.insertBlankLine();
        //     this._insertPart(operation.parts.bank_correspondent, 'CORRESPONDENTE BANCÁRIO');

        //     this._fileGenerator.insertBlankLine();
        //     this._fileGenerator.insertBlankLine();

        //     this._insertTitle('CARACTERÍSTICAS DA OPERAÇÃO');
        //     this._fileGenerator.insertBlankLine();

        //     this._insertOperationCaracteristic(operation.operation_characteristics);
        //     this._fileGenerator.insertBlankLine();
        //     this._fileGenerator.insertBlankLine();

        //     this._insertTitle('FLUXO DE PAGAMENTOS');
        //     this._fileGenerator.insertBlankLine();

        //     this._insertTableTitles({
        //         firstColumn: 'Parcela',
        //         secondColumn: 'Data de Vencimento',
        //         thirdColumn: 'Valor Total de Parcela',
        //         fourthColumn: 'Valor de Principal',
        //         fifthColumn: 'Valor de Juros',
        //     });

        //     operation.payment.forEach(item => {
        //         this._fileGenerator.insertLine({
        //             firstColumn: item.installment,
        //             secondColumn: DateTime.fromJSDate(new Date(item.expired)).toFormat('dd/MM/yyyy'),
        //             thirdColumn: item.total,
        //             fourthColumn: item.principal,
        //             fifthColumn: item.fee,
        //         });
        //     });
        //     this._fileGenerator.insertBlankLine();
        //     this._fileGenerator.insertBlankLine();
        //     this._fileGenerator.insertBlankLine();
        // });

        this._insertTableTitles(Object.values([
            'CÉDULA DE CREDITO Nº',
            'Razão Social_CREDOR',
            'CNPJ_CREDOR',
            'Endereço_CREDOR',
            'CEP_CREDOR',
            'Cidade_CREDOR',
            'UF_CREDOR',
            'Razão Social_EMITENTE',
            'CNPJ_EMITENTE',
            'Endereço_EMITENTE',
            'CEP_EMITENTE',
            'Cidade_EMITENTE',
            'UF_EMITENTE',
            'Razão Social_CORRESPONDENTE BANCÁRIO',
            'CNPJ_CORRESPONDENTE BANCÁRIO',
            'Endereço_CORRESPONDENTE BANCÁRIO',
            'CEP_CORRESPONDENTE BANCÁRIO',
            'Cidade_CORRESPONDENTE BANCÁRIO',
            'UF_CORRESPONDENTE BANCÁRIO',
            'Valor Líquido do Empréstimo (R$)',
            'Valor do IOF ( R$)',
            'Tarifa de Cadastro (R$)',
            'Taxa Efetiva de Juros (a.m.)',
            'Taxa Efetiva de Juros (a.a.)',
            'Despesas (R$)',
            'Custo Efetivo Total (a.m.) (%)',
            'Custo Efetivo Total (a.a.)',
            'Custo Efetivo Total (R$)',
            'Valor Total do Emprés.mo (SEM juros)',
            'Valor Total do Emprés.mo (COM juros)',
            'Data de Vencimento da CCB',
            'Praça de Pagamento desta CCB',
            'PARCELAS DEVIDAS',
            'Parcela',
            'Data de Vencimento',
            'Valor Total de Parcela',
            'Valor de Principal',
            'Valor de Juros',
        ]));

        data.forEach(item => {
            item.fgts_operation_installment.forEach(installment => {
                const map = new Map(item.fgts_operation_part.map(part => [part.fgts_operation_part_type_id, part]));
                this._fileGenerator.insertLine([
                    item.credit_id,
                    ...this._insertPart(map.get(1)),
                    ...this._insertPart(map.get(2)),
                    ...this._insertPart(map.get(3)),
                    ...this._insertOperationCaracteristic(item.fgts_operation_characteristic),
                    ...[
                        installment.installment,
                        DateTime.fromJSDate(new Date(installment.expired)).toFormat('dd/MM/yyyy'),
                        installment.total,
                        installment.principal,
                        installment.fee,
                    ],
                ]);
            });
        });

        return this._fileGenerator._fileContent;
    }
}

<template>
  <b-container>
    <b-card>
      <b-form-group
        label-for="files"
        label="Arquivos"
      >
        <multiselect
          id="files"
          v-model="filesSelected"
          :options="filesOptions"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder=""
          select-label=""
          selected-label=""
          deselect-label=""
          :show-no-options="false"
          label="text"
          track-by="value"
        />
      </b-form-group>

      <b-button
        variant="primary"
        class="mt-2"
        @click="downloadFile"
      >
        Baixar
      </b-button>
    </b-card>
  </b-container>
</template>

<script>
import {
    BContainer, BButton, BCard, BFormGroup,
} from 'bootstrap-vue';

import Multiselect from 'vue-multiselect';

import FgtsService from '@/service/fgts/fgts';
import GetFgtsFile from './download';
import { FileBuilderXlsx } from '../Conciliation/files/builder';

export default {
    components: {
        BContainer, BButton, Multiselect, BCard, BFormGroup,
    },

    data() {
        return {
            files: [],
            filesSelected: [],
        };
    },

    computed: {
        filesOptions() {
            return this.files.map(item => ({
                value: item.id,
                text: item.credit_id,
            }));
        },
    },

    async mounted() {
        const { data } = await FgtsService.getAvailableFiles();
        this.files = data.data;
    },

    methods: {
        async downloadFile() {
            this.callLoading(true);
            const selectedFiles = this.filesSelected.map(item => item.value);
            const selectedDataFiles = (await Promise.all(selectedFiles.map(async id => {
                const { data } = await FgtsService.getFileContent(id);
                return data.data;
            }))).flat(1);

            const generator = new GetFgtsFile();
            const fileGenerator = new FileBuilderXlsx(generator.generate(selectedDataFiles), 'CCB');
            (await fileGenerator.build()).download();

            const { data } = await FgtsService.getAvailableFiles();
            this.files = data.data;
            this.filesSelected = [];
            this.callLoading(false);
        },
    },
};
</script>

<style lang="scss">

.multiselect__option--highlight {
    background: #2E2C6C !important;
}

.multiselect__tag {
    background: #2E2C6C !important;
}

.dark-layout {
    .multiselect__tags {
        background-color: #283046;
        border-color: #3b4253;
    }
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

import methods from '@/enums/methods';
import Thoth from '../base/thoth';

class FgtsService extends Thoth {
    getFileContent(id) {
        return this.fetch({
            url: `/fgts/files/${id}`,
        });
    }

    getAvailableFiles() {
        return this.fetch({
            url: '/fgts/files',
        });
    }

    import(files) {
        const formData = new FormData();
        files.forEach((item, index) => formData.append(`file${index + 1}`, item));
        return this.fetch({
            data: formData,
            url: '/fgts',
            method: methods.POST,
        });
    }
}

export default new FgtsService();
